<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import DynamicTitle from "@/features/common/components/DynamicTitle.vue";
import { SuawInputGroup, SuawParagraph } from "@/components";
export default {
  name: "ResourcesFaq",
  components: { ResourceFrame, SuawInputGroup, SuawParagraph, DynamicTitle }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="general">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-faq-heading">Frequently Asked <b>Questions</b></h1>
      <DynamicTitle :before="['About']" size="medium" weight="bold" />
      <SuawParagraph text="<b>What is Shut Up & Write?</b>" />
      <SuawParagraph
        text='Shut Up & Write!, or SUAW, is an international writing community that provides the tools and support for writers to get their writing done. Our organizers host free events for writers all over the world. We are an initiative of <a href="https://www.writingpartners.org/" target="_blank" rel="noopener noreferrer">Writing Partners</a>, a nonprofit organization based in California dedicated to facilitating and cultivating creative communities worldwide.'
      />
      <SuawParagraph text="<b>How long has Shut Up & Write! existed? How did it get started?</b>" />
      <SuawParagraph
        text="Shut Up & Write! was started in San Francisco, CA by Rennie Saunders, a science fiction writer who liked the idea of using group support and accountability to help him stay motivated. In 2007, Rennie took the first step by organizing a small gathering of writers in a local café, inviting them to join in the experience of writing together."
      />
      <SuawParagraph
        text="Word quickly spread about these unique sessions, and what began as a small gathering blossomed into a movement. Shut Up & Write! became a beloved platform in 60 countries where writers of all backgrounds and skill levels could come together, share their passion, and nurture their creativity. The emphasis was not on critique or competition, but on the simple act of showing up and dedicating time to the craft."
      />
      <SuawParagraph text="<b>What is a Shut Up & Write! event?</b>" />
      <SuawParagraph
        text="Shut Up & Write! events, or “Shut Up & Writes!,” are hosted either in-person or online. Whether our writers meet in a local cafe or over a video call, the goal of a Shut Up & Write! is for writers to support each other by writing together quietly at a set time and place. Writers do not share their work or conduct critiques; the goal of our events is to foster productivity, creativity, and connection."
      />
      <SuawParagraph text="<b>What is an extended Shut Up & Write? What is a marathon?</b>" />
      <SuawParagraph
        text="Our organizers are all unique, which we value tremendously. This means some organizers prefer to write for different amounts of time than others. To help you navigate these different events, we use the following terms:"
      />
      <div class="suaw-faq-guide">
        <ul class="suaw-faq-guide__list">
          <li class="suaw-faq-guide__item">
            <SuawParagraph text="A classic Shut Up & Write! is an hour of focused writing time." />
          </li>
          <li class="suaw-faq-guide__item">
            <SuawParagraph text="An extended Shut Up & Write! is over an hour, but less than two hours." />
          </li>
          <li class="suaw-faq-guide__item">
            <SuawParagraph text="A Shut Up & Write! marathon is over two hours." />
          </li>
        </ul>
      </div>
      <SuawParagraph
        text="All of our events share our reliable format of checking in before and after your writing time. Check out our event listings, where events are flagged based on duration, and choose the format that works best for you!"
      />
      <SuawParagraph text="<b>Do people ever read their work aloud afterwards?</b>" />
      <SuawParagraph
        text="As our name implies, our writers get together to simply “Shut Up & Write!” While we often socialize afterwards, we do not schedule time for read-alouds or critiques. There's no pressure to show your work to anyone after a writing session—we focus on productivity."
      />
      <SuawParagraph text="<b>I'm interested in a specific type of writing (memoir, non-fiction, academic, etc)— is Shut Up & Write! a good fit for me?</b>" />
      <SuawParagraph
        text="Yes, absolutely. Shut Up & Write! helps anyone make progress on any writing project. Our writers work on screenplays, novels, short stories, poems, school essays, memoirs, blog posts, dissertations… you get the idea. Members come to our events to focus on their writing with no pressure, only encouragement and support."
      />
      <SuawParagraph text="<b>I'm a complete beginner and don't even have a current writing project. Is it still okay if I attend?</b>" />
      <SuawParagraph
        text="Yes! Shut Up & Write! is for writers of all skill levels, at any stage of the writing process. Because our events are spent quietly writing, no one will judge or critique you. It's a great place to write, edit, or simply outline some ideas for something you've always been wanting to write about."
      />
      <h2 class="suaw-faq-subtitle">
        In-Person Events
      </h2>
      <SuawParagraph text="<b>What happens at an in-person Shut Up & Write?</b>" />
      <SuawParagraph
        text="At our in-person events, writers of all genres meet up at a location of the organizer's choosing—usually a place like a coffee shop. The event starts by everyone introducing themselves and saying what they will be working on. Then the organizer sets a timer for an hour and tells everyone to “Shut Up & Write!” After a period of focused writing, the organizer calls time, and everyone briefly shares what they accomplished during the session. That's it! Members are welcome to stay and socialize after time is called, which can be a great way to meet other writers and discuss the craft. But primarily, our events are about making a regular commitment to writing and enjoying the support of others doing the same."
      />
      <SuawParagraph text="<b>What should I bring to an in-person Shut Up & Write?</b>" />
      <SuawParagraph
        text="Bring whatever you prefer to write with: your laptop, iPad, or even pen and paper. Sometimes in-person locations are noisy, so we recommend bringing headphones to minimize distractions. Power outlets and WiFi are not always available, so please plan accordingly."
      />
      <SuawParagraph text="<b>There aren't any events near me. Will someone please start one?</b>" />
      <SuawParagraph text="The best way to get an event near you is to apply to start your own! It's a great way to commit to your writing and help others do the same." />
      <SuawParagraph
        text="What do you think—are you interested? If so, you can apply to be an organizer here. Please note that we require our applicants to have attended at least one Shut Up & Write! event before applying to host their own."
      />
      <h2 class="suaw-faq-subtitle">
        Online Events
      </h2>
      <SuawParagraph text="<b>What can I expect from a Shut Up & Write! online event?</b>" />
      <SuawParagraph
        text="Our online event format is very similar to our in-person format. At a featured online event, expect a quick round of introductions, then an hour of writing, with optional socializing afterwards. Some of our organizers choose to host longer online events, as we do allow some flexibility with the online format. However, a featured online event will always have one hour of writing time."
      />
      <SuawParagraph text="<b>What equipment and software do I need to attend an online event?</b>" />
      <SuawParagraph
        text="Our online events are hosted via Zoom or other similar video platforms, based on the choice of the event host. For Zoom events, you'll need a computer capable of running Zoom, a stable internet connection, and a Zoom account."
      />
      <SuawParagraph text='You can download <a href="https://zoom.us/download" target="_blank" rel="noopener noreferrer">Zoom</a> here.' />
      <SuawParagraph text="<b>Do I need to pay for Zoom to attend an online event?</b>" />
      <SuawParagraph text="No. As long as you are registered with Zoom, you may attend our online events free of charge for the full duration of the event." />
      <h2 class="suaw-faq-subtitle">
        User Accounts
      </h2>
      <SuawParagraph text="<b>My username and password aren't working / I can't log in to my account.</b>" />
      <SuawParagraph
        text='Sorry to hear that! If you are unable to access your account, email <a href="mailto:questions@shutupwrite.com">questions@shutupwrite.com</a> and we will help resolve the issue. Our help desk is available Monday through Friday, 9am - 5pm Pacific time.'
      />
      <SuawParagraph text="<b>I have an account on Meetup. Can I use my Meetup login information here?</b>" />
      <SuawParagraph
        text='Our platform at <a href="/">shutupwrite.com</a> is independent from <a href="https://www.meetup.com/" target="_blank" rel="noopener noreferrer">meetup.com</a>, so you will need to create a separate account for this site. Your <a href="https://www.meetup.com/" target="_blank" rel="noopener noreferrer">meetup.com</a> username and password will not work on shutupwrite.com.'
      />
      <SuawParagraph text="<b>How do I unsubscribe from reminders to RSVP to events in a series?</b>" />
      <p class="suaw-paragraph">
        To stop receiving RSVP reminders for events on shutupwrite.com, unsubscribe from the event series you no longer want reminders for. Log into shutupwrite.com and go to
        your
        <router-link :to="{ name: 'DashboardEvents' }">My Events</router-link> page. Click on the event card for the series you want to unsubscribe from, then click the green
        <b>Subscribe</b> button. This will open a pop-up where you can unsubscribe.
      </p>
      <SuawParagraph
        text='If you want to unsubscribe from notifications on Meetup, you can learn more about how to do that on Meetup.com <a href="https://help.meetup.com/hc/en-us/sections/360000208211-Notification-settings" target="_blank" rel="noopener noreferrer">here</a>.'
      />

      <h2 class="suaw-faq-subtitle">
        Organizing Events
      </h2>
      <SuawParagraph text="<b>What does becoming an organizer entail?</b>" />
      <SuawParagraph
        text="If you'd like to apply to become an organizer, we first ask that you attend a Shut Up & Write! event so you're familiar with our format. You'll apply using our online form, and schedule a video interview with one of our staff members. If your application is approved, we'll help you set up your event listings, and you can start hosting!"
      />
      <SuawParagraph text="<b>What are the benefits of being an organizer?</b>" />
      <SuawParagraph
        text="It's a great way to commit to your writing and connect with other writers in your area. It's also a lot of fun! Organizers make a real difference in their communities, and enjoy being part of a movement bigger than just a single writing group."
      />
      <SuawParagraph text="<b>What is the time commitment for being an organizer?</b>" />
      <SuawParagraph
        text="It takes less than two hours a week to plan and host a regular Shut Up & Write! event. We do ask that our organizers check their email and promptly respond to anything related to their event."
      />
      <SuawParagraph text="<b>How do I apply to be an organizer?</b>" />
      <SuawParagraph
        text='Get started with the organizer application process <a href="https://shutupwrite.typeform.com/to/UCzcz0LO?typeform-source=shutupwrite.com" target="_blank" rel="noopener noreferrer">here</a>.'
      />
      <h2 class="suaw-faq-subtitle">
        I have another question that isn't listed here.
      </h2>
      <SuawParagraph
        text='We&#39;d love to hear from you! Please reach out to us at <a href="mailto:questions@shutupwrite.com">questions@shutupwrite.com</a>. Our help desk is available Monday through Friday, 9am - 5pm Pacific time.'
      />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-faq-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-faq-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}
//list
.suaw-faq-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
